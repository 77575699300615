// @flow
import React from 'react'
import Head from 'next/head'

import { useIntl } from 'react-intl'
import Facebook from './facebook'
import messages from './messages'
import Twitter from './twitter'

const BASE_URL = 'https://www.soundonlive.com'

interface Props {
  children?: React.ReactNode
  title?: string
  description?: string
  url?: string
  image?: string
  user?: string
  isPrivate?: boolean
}

const AppHead = ({
  children,
  // url,
  image,
  user,
  isPrivate,
  ...props
}: Props) => {
  const intl = useIntl()
  // eslint-disable-next-line react/destructuring-assignment
  const title = props.title
    ? // eslint-disable-next-line react/destructuring-assignment
    `${props.title} | SOUND ON LIVE`
    : intl.formatMessage(messages.title)
  // eslint-disable-next-line react/destructuring-assignment
  const description = props.description
    ? // eslint-disable-next-line react/destructuring-assignment
    props.description.replace(/\r?\n|\r/g, '')
    : intl.formatMessage(messages.description)
  // eslint-disable-next-line react/destructuring-assignment
  const url = `${BASE_URL}${props.url}`

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta key="description" name="description" content={description} />
        {
          // private / draft sounds or etc...
          isPrivate && (
            <meta key="robots" name="robots" content="noindex,nofollow" />
          )
        }
        <link key="canonical" rel="canonical" href={url} />
        {/* <link key="author" rel="author" href="" />
      <link key="publisher" rel="publisher" href="" /> */}
        <meta key="prop-name" itemProp="name" content={title} />
        <meta
          key="prop-description"
          itemProp="description"
          content={description}
        />
        {image && <meta key="prop-image" itemProp="image" content={image} />}
        {children}
      </Head>
      <Facebook
        url={url}
        title={title}
        image={image}
        description={description}
      />
      <Twitter
        creator={user}
        url={url}
        title={title}
        description={description}
        image={image}
      />
    </>
  )
}

AppHead.defaultProps = {
  children: null,
  title: '',
  description: '',
  image: '',
  user: '',
  url: '/',
  isPrivate: false,
}

export default AppHead
