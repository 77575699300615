import { getFeatureSounds, getFeatureUsers } from '@/data/api/feature'
import Head from '@/components/common/head'
import HomePage from '@/views/home'

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  featured?: Array<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  featuredUser?: Array<any>
}

const Home = ({ featured = [], featuredUser = []}: Props) => {
  return (
    <>
      <Head />
      <HomePage featured={featured} featuredUser={featuredUser} />
    </>
  )
}

export const getStaticProps = async () => {
  const featured = await getFeatureSounds()
  const featuredUser = await getFeatureUsers()

  return {
    props: {
      featured,
      featuredUser,
    },
    revalidate: 60,
  }
}

export default Home
