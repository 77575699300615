import styles from './loading.module.scss'

type Props = {
  loader?: number
}

export default function Loading({ loader = 1 }: Props) {
  if (loader === 1) {
    return (
      <div className={styles.loaderContainer}>
        <div className={styles.wrap}>
          <div className={styles.bar} />
          <div className={styles.bar} />
          <div className={styles.bar} />
          <div className={styles.bar} />
          <div className={styles.bar} />
          <div className={styles.ball} />
        </div>
        <div className={styles.text}>Now Loading...</div>
      </div>
    )
  }

  return (
    <div className={styles.loader}>
      <div className={styles.bounce} />
      <div className={styles.text}>Now Loading...</div>
    </div>
  )
}
