import React, { useState } from 'react'
import Avatar from '@components/app/avatar'
import Coverart from '@components/app/coverart'
import Waveform from '@components/app/waveform'
import Button from '@components/common/button/button'
import LinkButton from '@components/common/button/link'
import SigninModal from '@components/modals/signin'
import dayjs from 'dayjs'
import Image from 'next/image'
import Link from 'next/link'
import { FormattedMessage } from 'react-intl'
import useSWR from 'swr'
import useAuth from '@/hooks/auth'
// import BannerAina from '@/components/banner/aina'
import Loading from '@/components/common/loading'
import { getFeatureSounds, getFeatureUsers } from '../../data/api/feature'
import MediaLink from './media-link'
import PickupArtist from './pickup-artist'
import styles from './home.module.scss'

const getMedia = (media) =>
  media.type === 'Sound'
    ? { mediaType: media.type, ...media.sound }
    : { mediaType: media.type, ...media.album }

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  featured: Array<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  featuredUser: Array<any>
}

// const loadFeaturedUsers = () => getFeature('User')

const HomePage = ({ featured, featuredUser }: Props) => {
  const [ isOpen, setOpen ] = useState(false)
  const toggleModal = () => {
    setOpen(!isOpen)
  }
  const { loggedin } = useAuth()

  // eslint-disable-next-line max-len
  const { data: soundData } = useSWR('/api/feature/Sound', getFeatureSounds, {
    fallbackData: featured,
  })
  const { data: userData } = useSWR('/api/feature/User', getFeatureUsers, {
    fallbackData: featuredUser,
  })

  // const [ featuredFirst, ...featuredList ] = data.map(media => getMedia(media))
  let featuredFirst
  const featuredList1 = []
  const featuredList2 = []

  soundData.forEach((media, index) => {
    const featuredData = getMedia(media)
    if (index === 0) {
      featuredFirst = featuredData
    }
    else if (index <= 4) {
      featuredList1.push(featuredData)
    }
    else if (index <= 8) {
      featuredList2.push(featuredData)
    }
  })

  const featuredUsersList = []
  userData.forEach((fuser) => {
    if (fuser.type !== 'User') {
      return
    }
    featuredUsersList.push(fuser.user)
  })

  return (
    <>
      <header className={`${styles.hero} d-flex align-items-center mb-5`}>
        <div
          className={`${styles.inner} d-md-flex align-items-center justify-content-center`}
        >
          <div className={`${styles.left} flex1 tx-center`}>
            <Image
              src="/static/images/jump@2x.png"
              alt="Jump"
              width={305}
              height={501}
            />
          </div>
          <div className={`${styles.right} flex1`}>
            <h1 className={`${styles.h1} tx-oswald tx-46 tx-w700`}>
              Amazingly Simple Music Share
              <br />
              Support the Artists
            </h1>
            <h2 className={`${styles.catch} tx-w300 mb-5`}>
              <FormattedMessage
                id="home.catch"
                // eslint-disable-next-line max-len
                defaultMessage="Music sharing made easy"
              />
            </h2>
            <div>
              {loggedin ? (
                <LinkButton href="/sound-new" className="primary-alt xl">
                  <FormattedMessage
                    id="app.upload"
                    defaultMessage="Upload new sound"
                  />
                </LinkButton>
              ) : (
                <>
                  <LinkButton href="/is/for-artists" className="xl mr-3">
                    <FormattedMessage
                      id="home.more"
                      defaultMessage="Find out more"
                    />
                  </LinkButton>
                  <Button className="primary-alt xl" onClick={toggleModal}>
                    <FormattedMessage
                      id="app.getstart"
                      defaultMessage="Get Started"
                    />
                  </Button>
                </>
              )}
              {/* <LinkButton className="primary-alt">
              <FormattedMessage
                id="home.getstarted"
                defaultMessage="Get Started"
              />
            </LinkButton> */}
            </div>
          </div>
        </div>
      </header>
      
      {/* <BannerAina /> */}
      
      <main className="container">
        <h2 className={`${styles.h2} tx-oswald tx-w800 tx-center mb-3`}>
          Share music made easy
        </h2>
        <p className={`${styles.lead} tx-gish tx-center mb-5`}>
          <FormattedMessage
            id="home.lead"
            // eslint-disable-next-line max-len
            defaultMessage="SOUND ON LIVE made easy to upload your sounds, make it streaming, and selling to get supported.{br}You can also search your favorite artists and listen to their sounds, or buy the sound to support the artists"
            values={{
              br: <br />,
            }}
          />
        </p>
        <div className="tx-center mb-5">
          <LinkButton href="/is/for-artists" className="xl">
            <FormattedMessage id="home.more" defaultMessage="Find out more" />
          </LinkButton>
        </div>
        <div className={`${styles.hr} mb-5`} />
        {soundData ? (
          <h2 className="tx-oswald tx-w300 mb-5">Featured on SOUND ON LIVE</h2>
        ) : (
          <Loading />
        )}
        {soundData.length > 0 && (
          <>
            <div className="d-md-flex mb-5">
              <div className={styles.featuredFirst}>
                <div className={`${styles.featuredInner} mb-5`}>
                  <MediaLink media={featuredFirst}>
                    <figure className="ps-relative shadow-coverart mb-4">
                      <Coverart
                        src={featuredFirst.coverart_crop_url}
                        alt={featuredFirst.title}
                      />
                      <div className={styles.featureFirstWaveform}>
                        <Waveform
                          id={featuredFirst.id}
                          src={featuredFirst.waveform_url}
                          duration={featuredFirst.metadata.duration}
                          outerColor="rgba(255, 255, 255, .6)"
                          static
                          hideTime
                        />
                      </div>
                    </figure>
                  </MediaLink>
                  <MediaLink className="alt" media={featuredFirst}>
                    <h3 className="mb-2">{featuredFirst.title}</h3>
                  </MediaLink>

                  <MediaLink className="nohover" media={featuredFirst}>
                    <p className="tx-14 tx-gish-light tx-ellipsis3 mb-3">
                      {featuredFirst.description ? (
                        <>
                          {featuredFirst.description.substr(0, 100)}
                          {featuredFirst.description.length > 100 && '...'}
                        </>
                      ) : (
                        <>
                          Released&nbsp;{dayjs(featuredFirst.released_at).format('YYYY-MM-DD')}
                        </>
                      )}
                    </p>
                  </MediaLink>

                  <div className={styles.userarea}>
                    <Link
                      href={`/user/${featuredFirst.user.username}`}
                    >
                      <Avatar
                        className="mr-2"
                        src={featuredFirst.user.user_image}
                        width="24"
                        alt={featuredFirst.user.name}
                      />
                    </Link>
                    <Link
                      href={`/user/${featuredFirst.user.username}`}
                      className="alt"
                    >
                      {featuredFirst.user.name}
                    </Link>
                    <span className="dot" />
                    <time dateTime={featuredFirst.released_at.toString()}>
                      {dayjs(featuredFirst.released_at).format('YYYY-MM-DD')}
                    </time>
                  </div>
                </div>
              </div>
              <div className={styles.featured}>
                {featuredList1.map((media) => (
                  <div key={media.id} className="d-flex mb-5">
                    <div className={`${styles.mediaCover} flex0`}>
                      <MediaLink className="d-block" media={media}>
                        <figure className="shadow-coverart mb-0">
                          <Coverart
                            src={media.coverart_crop_url}
                            alt={media.title}
                          />
                        </figure>
                      </MediaLink>
                    </div>
                    <div className="flex1">
                      <MediaLink className="d-block alt mb-3" media={media}>
                        <h3>{media.title}</h3>
                      </MediaLink>

                      <MediaLink className="d-block nohover mb-3" media={media}>
                        <p className="tx-ellipsis3 tx-14 tx-gish-light tx-ellipsis3 mb-0">
                          {media.description ? (
                            <>
                              {media.description.substr(0, 100)}
                              {media.description.length > 100 && '...'}
                            </>
                          ) : (
                            <>
                              Released&nbsp;{dayjs(media.released_at).format('YYYY-MM-DD')}
                            </>
                          )}
                        </p>
                      </MediaLink>
                      <div className={styles.userarea}>
                        <Link
                          href={`/user/${media.user.username}`}
                        >
                          <Avatar
                            className="mr-2"
                            src={media.user.user_image}
                            width="24"
                            alt={media.user.name}
                          />
                        </Link>
                        <Link
                          href={`/user/${media.user.username}`}
                          className="alt"
                        >
                          {media.user.name}
                        </Link>
                        <span className="dot" />
                        <time className="tx-g600" dateTime={media.released_at}>
                          {dayjs(media.released_at).format('YYYY-MM-DD')}
                        </time>
                      </div>
                      <Waveform
                        id={media.id}
                        className="mt-2"
                        src={media.waveform_url}
                        duration={media.duration}
                        outerColor="rgba(0, 0, 0, .3)"
                        static
                        hideTime
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className={`${styles.featuredList} row mb-5`}>
              {featuredList2.map((media) => (
                <div className={styles.col} key={media.id}>
                  <MediaLink media={media}>
                    <figure className="shadow-coverart relative mb-4 aspect-square">
                      <Coverart
                        src={media.coverart_crop_url}
                        alt={media.title}
                      />
                      <Waveform
                        id={media.id}
                        className={styles.featureFirstWaveform}
                        src={media.waveform_url}
                        duration={media.duration}
                        outerColor="rgba(255, 255, 255, .6)"
                        static
                        hideTime
                      />
                    </figure>
                  </MediaLink>
                  <MediaLink className="alt" media={media}>
                    <h3 className="mb-2">{media.title}</h3>
                  </MediaLink>

                  <MediaLink className="nohover" media={media}>
                    <p className="tx-14 tx-gish-light tx-ellipsis3 mb-3">
                      {media.description ? (
                        <>
                          {media.description.substr(0, 100)}
                          {media.description.length > 100 && '...'}
                        </>
                      ) : (
                        <>
                          Released&nbsp;{dayjs(media.released_at).format('YYYY-MM-DD')}
                        </>
                      )}
                    </p>
                  </MediaLink>

                  <div className={styles.userarea}>
                    <Link
                      href={`/user/${media.user.username}`}
                      className="alt"
                    >
                      <Avatar
                        className="mr-2"
                        src={media.user.user_image}
                        width="24"
                        alt={media.user.name}
                      />
                      {media.user.name}
                    </Link>
                    <span className="dot" />
                    <time dateTime={media.released_at}>
                      {dayjs(media.released_at).format('YYYY-MM-DD')}
                    </time>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
        <PickupArtist users={featuredUsersList} />

        <div className="tx-center mb-5">
          <LinkButton href="/explore" className="xl">
            <FormattedMessage
              id="home.explore"
              defaultMessage="Explore Sounds"
            />
          </LinkButton>
        </div>
      </main>
      <SigninModal open={isOpen} onClose={() => setOpen(false)} />
    </>
  )
}

HomePage.defaultProps = {}

export default HomePage
