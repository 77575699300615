import { post, get } from '../../utils/fetch'

export type FeatureType = 'User' | 'Sound' | 'Album'

export const getFeature = async (type: FeatureType, perpage = 20, page = 1) => {
  const result = await get(`/feature/${type}?perpage=${perpage}&page=${page}`)
  if (result.error) {
    return []
  }
  return result
}

export const getFeatureSounds = () => getFeature('Sound')

export const getFeatureUsers = async (perpage = 12, page = 1) => {
  const result = await get(`/feature/User?perpage=${perpage}&page=${page}`)
  if (result.error) {
    return []
  }
  return result
}

export const addFeature = async (type: FeatureType, id: string) => {
  const result = await post(`/feature/${id}`, { type })
  return result
}
