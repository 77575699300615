// @flow
import React from 'react'
import Head from 'next/head'

interface Props {
  creator?: string
  url: string
  title?: string
  description?: string
  image?: string
}

const TwitterCard = ({ creator, url, title, description, image }: Props) => (
  <Head>
    <meta
      key="tw:card"
      name="twitter:card"
      content={image !== '' ? 'summary' : 'summary_large_image'}
    />
    <meta key="tw:site" name="twitter:site" content="@soundonlive" />
    {creator && (
      <meta key="tw:creator" name="twitter:creator" content={`@${creator}`} />
    )}
    <meta key="tw:url" name="twitter:url" content={url} />
    {/* eslint-disable-next-line max-len */}
    <meta key="tw:title" name="twitter:title" content={title} />
    {/* eslint-disable-next-line max-len */}
    <meta
      key="tw:description"
      name="twitter:description"
      content={description}
    />
    <meta
      key="tw:image"
      name="twitter:image"
      content={
        image !== ''
          ? image
          : 'https://www.soundonlive.com/static/images/ogp.png'
      }
    />
  </Head>
)

TwitterCard.defaultProps = {
  creator: '',
  title: '',
  image: '',
  description: '',
}

export default TwitterCard
