import React from 'react'
import Link from 'next/link'
import { UserProps } from '../../../types/user'
import styles from './pickup-artist.module.scss'
import Avatar from '@components/app/avatar'


interface UserLinkProps {
  children: React.ReactNode
  className?: string
  name: string
}

const UserLink = ({ children, className, name }: UserLinkProps) => (
  <Link href={`/user/${name}`} className={className}>
    {children}
  </Link>
)

interface Props {
  users: UserProps[]
}

const PickupArtist = ({ users }: Props) => (
  <div className={styles.container}>
    <h2 className="tx-oswald tx-w300 mb-5">Pickup Artists</h2>
    {users.length > 0 && (
      <div className={`${styles.featuredList} row mb-5`}>
        {users.map((user) => (
          <div className={`${styles.col} tx-center mb-5`} key={user.id}>
            <UserLink name={user.username}>
              <Avatar
                className={`${styles.avatar} mb-3`}
                src={user.user_image}
                alt={user.name}
              />
            </UserLink>
            <UserLink className="alt" name={user.username}>
              <h3 className="tx-16 tx-w500 mb-2">{user.name}</h3>
            </UserLink>
          </div>
        ))}
      </div>
    )}
  </div>
)

UserLink.defaultProps = {
  className: '',
}

export default PickupArtist
