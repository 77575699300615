import React from 'react'
import Link from 'next/link'

interface MediaLinkProps {
  children: React.ReactNode
  className?: string
  media: {
    mediaType: 'Sound' | 'Album'
    id: string
    slug: string
  }
}

const MediaLink = ({ children, className, media }: MediaLinkProps) => {
  const href = `/${media.mediaType === 'Sound' ? 'sound' : 'album'}/${media.id}/${media.slug}`
  return (
    <Link
      href={href}
      className={className}
    >
      {children}
    </Link>
  )
}

MediaLink.defaultProps = {
  className: '',
}

export default MediaLink
