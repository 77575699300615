// @flow
import React from 'react'
import Head from 'next/head'

interface Props {
  url: string
  // type?: string,
  title?: string
  image?: string
  description?: string
}

const TwitterCard = ({
  url,
  // type,
  title,
  image,
  description,
}: Props) => (
  <Head>
    <meta key="fb:app_id" property="fb:app_id" content="1398695963738160" />
    <meta key="og:url" property="og:url" content={url} />
    <meta key="og:type" property="og:type" content="website" />
    {/* eslint-disable-next-line max-len */}
    <meta key="og:title" property="og:title" content={title} />
    <meta
      key="og:image"
      property="og:image"
      content={
        image !== ''
          ? image
          : 'https://www.soundonlive.com/static/images/ogp.png'
      }
    />
    {/* eslint-disable-next-line max-len */}
    <meta
      key="og:description"
      property="og:description"
      content={description}
    />
    <meta key="og:site_name" property="og:site_name" content="SOUND ON LIVE" />
  </Head>
)

TwitterCard.defaultProps = {
  title: '',
  image: '',
  description: '',
}

export default TwitterCard
