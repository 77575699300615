import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'title',
    defaultMessage:
      'SOUND ON LIVE - Amazingly Simple Music Share Support the Artists',
  },
  description: {
    id: 'description',
    defaultMessage:
      'Explore community of artists, bands, podcasters and creators of music & audio',
  },
})
