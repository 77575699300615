import CdnImage from '../cdn-image'

interface Props {
  className?: string
  src?: string
  alt: string
  width?: number
}

const Coverart = ({
  className = '',
  src,
  alt = '',
  width = 300,
}: Props) => {
  return (
    <CdnImage
      className={`aspect-square ${className}`}
      src={src}
      width={width}
      height={width}
      alt={alt}
    />
  )
}

export default Coverart
